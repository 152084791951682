<!--
 * @Author: 月魂
 * @Date: 2021-12-20 15:07:45
 * @LastEditTime: 2022-11-14 17:58:10
 * @LastEditors: 月魂
 * @Description: 三秦拾味列表页
 * @FilePath: \food-show\src\views\Taste.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="this.$t('taste.title')" />
      <img src="../assets/temp/taste.png" alt="" class="img top-bg">
      <img src="../assets/temp/taste-pc.png" alt="" class="img top-bg pc">
      <!-- <img :src="domain + this.$t('taste.posterUrl')" alt="" class="img"> -->
      <div class="video">
        <div class="item">
          <img src="https://image.wearetogether.com.cn/time-honored-1.png" alt="" class="img" @click="jumpToVideo">
          <div class="bottom">
            <div class="title">{{ this.$t('taste.thousand.title') }}</div>
            <van-icon name="arrow" size="16" class="icon" />
          </div>
        </div>
      </div>
      <div class="intro">
        <div class="des" v-for="(item, index) in this.$t('taste.des')" :key="index">{{ item }}</div>
      </div>
      <!-- 奇石宴部分 -->
      <div class="stone part">
        <div class="text">
          <div class="title">{{ this.$t('taste.stone.title') }}</div>
          <div class="des">{{ this.$t('taste.tips') }}</div>
        </div>
        <img :src="domain + this.$t('taste.stone.posterUrl')" alt="" class="img stone-img" @click="showImg">
      </div>
      <!-- 菜谱部分 -->
      <div class="part food">
        <div class="title">{{ this.$t('taste.food.title') }}</div>
        <div class="food-cont" v-for="(item, index) in this.$t('taste.menu')" :key="index" @click="jumpToFood(index)">
          <div class="left">
            <img :src="domain + item.imgUrl" alt="">
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="des">{{ item.des[0] }}</div>
          </div>
        </div>
      </div>
      <!-- 图片预览组件 -->
      <div class="imgPreview">
        <van-image-preview ref="imgPreview" v-model="show" :images="imgList" @change="onChange">
          <template v-slot:cover>
            <div class="img-name">{{ name }}</div>
            <van-icon name="arrow-left" size="32" class="arrow-left" @click="handleBefore" />
            <van-icon name="arrow" size="32" class="arrow-right" @click="handleAfter" />
          </template>
        </van-image-preview>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Navigation from '@/components/Navigation'
import '@vant/touch-emulator';
import { NavBar, ImagePreview } from 'vant';

Vue.use(NavBar).use(ImagePreview);

export default {
  name: 'Taste',
  components: {
    Navigation
  },
  data() {
    return {
      title: '',
      domain: '',
      show: false,
      index: 0,
      name: '',
      imgList: []
    }
  },
  mounted() {
    this.domain = this.$t('domain')
    this.title = this.$t('taste.title')
    this.wxShare(this.$t('title'), this.$t('taste.title'))
    Object.values(this.$t('taste.stone.list')).forEach(item => {
      this.imgList.push(this.domain + item.url)
    })
    this.name = this.$t('taste.stone.list')[0].name
  },
  methods: {
    showImg() {
      this.index = 0;
      this.name = this.$t('taste.stone.list')[0].name
      this.show = true;
    },
    onChange(index) {
      console.log(index)
      this.index = index;
      this.name = this.$t('taste.stone.list')[index].name
    },
    jumpToVideo() {
      this.$router.push('/taste/video')
    },
    jumpToFood(id) {
      this.$router.push(`/taste/food/${id}`)
    },
    handleBefore() {
      this.index -= 1
      this.$refs.imgPreview.swipeTo(this.index)
    },
    handleAfter() {
      this.index += 1
      this.$refs.imgPreview.swipeTo(this.index)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
  }
  .top-bg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .pc {
    display: none;
  }
  @media (min-width: 1000PX) {
    .top-bg {
      display: none;
    }
    .pc {
      display: block;
    }
  }
  .video {
    padding: 16px;
    font-size: 12px;
    position: relative;
    z-index: 1;
    .item {
      margin-bottom: 16px;
      border-radius: 15px;
      background: #111135;
      overflow: hidden;
    }
    .img {
      display: block;
      width: 100%;
      border-radius: 15px;
    }
    .bottom {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      .title {
        font-size: 18px;
        color: #fff;
      }
      .icon {
        color: #fff;
        display: flex;
        align-items: center;
        margin-left: 16px;
      }
    }
  }
  .intro {
    width: 100%;
    padding: 0 16px;
    max-width: 1200PX;
    margin-top: 8px;
    color: #999;
    .des {
      font-size: 12px;
    }
  }
  .part {
    padding: 12px 16px;
    max-width: 1200PX;
    margin: auto;
    margin-top: 8px;
    .text {
      width: 100%;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .title {
        font-size: 18px;
        line-height: 32px;
        padding-left: 8px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 3px;
          height: 18px;
          background: #fff;
          // border-radius: 3px 3px;
        }
      }
      .des {
        color: #999;
        font-size: 12px;
        line-height: 32px;
      }
    }
    .stone-img {
      border-radius: 15px;
    }
    
    video, img {
      display: block;
      width: 100%;
    }
    .title {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 16px;
      position: relative;
      padding-left: 8px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3px;
        height: 18px;
        background: #fff;
        // border-radius: 3px 3px;
      }
    }
    .food-cont {
      width: 100%;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      background: #111135;
      overflow: hidden;
      padding: 16px 8px;
      margin-bottom: 16px;
      .left {
        width: 110px;
        height: 110px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .right {
        width: 200px;
        display: flex;
        flex-wrap: wrap;
        align-items: normal;
        .title {
          font-size: 16px;
          line-height: 24px;
          padding-left: 0;
          &:before {
            width: 0;
            height: 0;
          }
        }
        .des {
          font-size: 12px;
          color: #999;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      @media (min-width: 800PX) {
        & {
          justify-content: normal;
          padding-right: 16px;
          .left {
            width: 200px;
            height: 200px;
            box-sizing: border-box;
            flex-shrink: 0;
          }
          .right {
            width: calc(100% - 64px);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 32px;
          }
        }
      }
    }
  }
  .img-name {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
  .arrow-left {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50px;
    margin: auto;
    align-items: center;
    cursor: pointer;
  }
  .arrow-right {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 50px;
    margin: auto;
    align-items: center;
    cursor: pointer;
  }
  @media (min-width: 800PX) {
    .arrow-left, .arrow-right {
      display: flex;
    }
  }
}
</style>
